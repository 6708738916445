export enum Municipality {
  Centar = 1,
  NoviGrad = 2,
  NovoSarajevo = 3,
  StariGrad = 4,
  Hadžići = 5,
  Ilidža = 6,
  Ilijaš = 7,
  Trnovo = 8,
  Vogošća = 9,
}
